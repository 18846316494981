<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <b-container fluid class="documents p-0 pt-3 p-md-3 pb-md-5 pr-md-5 m-md-2 mt-md-5">
    <b-row>
      <b-col cols="12" class="pr-md-5">
        <b-container fluid>
          <b-row>
            <b-col cols="12" md="7" class="mb-md-4">
              <h2 class="d-none d-md-block pb-0 mb-0">{{getLeaveHeader(selectedLeave.leaveType, selectedLeave.leaveId)}}</h2>
              <h2 class="d-block d-md-none text-center pb-0 mb-0">{{getLeaveHeader(selectedLeave.leaveType, selectedLeave.leaveId)}}</h2>
              <p class="mb-0 leave-dates">[{{formatDate(selectedLeave.minStartDate)}} - {{formatDate(selectedLeave.maxEndDate)}}]</p>
            </b-col>
            <b-col cols="12" md="5" class="text-center text-md-right p-0">
              <b-dropdown :text="getLeaveHeader(selectedLeave.leaveType, selectedLeave.leaveId)" variant="outline-secondary" class="m-2">
                <b-dropdown-item v-for="leave in getDropdownItems()" v-bind:key="leave.leaveId" @click="selectLeave(leave)">{{getLeaveHeader(leave.leaveType, leave.leaveId)}}</b-dropdown-item>
              </b-dropdown>
            </b-col>
          </b-row>
        </b-container>
        <b-table isRowHeader hover borderless :items="documents" :fields="fields" class="mb-5 actionitem-table">
          <template v-slot:cell(name)="row">
            <a :href="downloadFileLink(row.item.id)" class="action-link mb-1" download><i class="icon icon-file-download2"></i> {{row.item.name}}</a>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import ApiProperties from '../../../src/main/resources/application'
  import leaveMixin from '@/mixins/leaveMixin.js';
  import dateMixin from '@/mixins/dateMixin.js';
  import FileUpload from '@/components/FileUpload.vue';

  export default {
    name: 'documents',
    data: function() {
      return {
        leaves: [],
        selectedLeave: {},
        documents: [],
        fields: [
          {key: "name", label: 'Your Documents' }
        ],

        errors: []
      }
    },

    components: {FileUpload},

    mixins: [leaveMixin, dateMixin],

    computed: {
      iconImage: function() {
        let iconClass = this.getIconClassFromLeaveType('sm', this.selectedLeave.leaveType, this.$root.user.gender, 'generic');

        return this.getIconImageFromClassName(iconClass, 'sm');
      }
    },

    methods: {
      getLeaveHeader: function(type, id) {
        return `${type} Leave (${id})`;
      },

      getLeavesByPortalUser: function() {
        this.$http.get(ApiProperties.api.leavesByPortalUser).then(response => {
          this.leaves = response.data;

          if (this.leaves!==undefined && this.leaves!==null && this.leaves.length>0) {
            this.selectedLeave = this.leaves[0];
            this.getDocumentsByLeave(this.leaves[0].leaveId);
          }
        }).catch(e => {
          this.errors.push(e);
        })
      },

      getDocumentsByLeave: function(leaveId) {
        let pathParams = {pathVars: {leaveId: leaveId}};

        this.$http.get(ApiProperties.api.documentsByLeave, pathParams).then(response => {
          this.documents = response.data.documents;
        }).catch(e => {
          this.errors.push(e);
        })
      },

      getDropdownItems: function() {
        let _this = this;

        return this.leaves.filter((leave) => {
          return leave.leaveId !== _this.selectedLeave.leaveId;
        });
      },

      selectLeave: function(leave) {
        this.selectedLeave = leave;
        this.getDocumentsByLeave(leave.leaveId);
      },

      executeUploadFileForm: function(inputElementId) {
        let fileInputEl = document.getElementById(inputElementId);
        fileInputEl.click();
      },

      getUploadLabel: function(actionItem) {
        return (this.isUploadPendingClient(actionItem)) ? 'Upload' : 'Upload Again';
      }
    },

    created() {
      this.getLeavesByPortalUser();

      this.$root.$on('StateChange', ()=> {
        this.getDocumentsByLeave(this.selectedLeave.leaveId);
      });
    }
  }
</script>
<style lang="scss">
  @import '../scss/app.scss';

  .documents {
    padding-top: 46px !important;

    .icon-image {
      width: 100%;
      height:auto;
    }

    .leave-dates {
      color: #acacac;
      font-size: .8rem;
      font-weight: 600;
      text-align: center;

      @include media-breakpoint-up(md) {
        text-align: left;
      }
    }

    .status-completed {
      color: #05cb05;
    }

    .status-pending {
      color: #acacac;
    }

    .status-none {
      color: #acacac;
    }

    .actionitem-table {
      &.table {
        tr {
          @extend .mb-4;
          @extend .mb-md-0;
        }
        td {
          @extend .py-md-1;
        }
      }
    }
  }
</style>